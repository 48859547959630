import { render, staticRenderFns } from "./applayout.vue?vue&type=template&id=9431a236&lang=pug&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_xux2r3ymb3rsvhwuofdjusdmeu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports