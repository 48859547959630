import Vue from 'vue';
import Router from 'vue-router';
import heartbeats from './vue/heartbeats';
import filteredErrorList from './vue/error/filtered';
import latestErrorList from './vue/error/latest';
import systemList from './vue/system/list';
import filterList from './vue/filter/list';
import entryList from './vue/entry/list';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    { path: '/', component: heartbeats },
    { path: '/filtered/', component: filteredErrorList },
    { path: '/latest/', component: latestErrorList },
    { path: '/systems/', component: systemList },
    { path: '/filters/', component: filterList },
    { path: '/entries/', component: entryList },
  ],
});
